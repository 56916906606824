<template>
  <b-modal
    ref="refModal"
    id="taxi-bank-account-modal"
    title="Bank Account Details"
    :ok-title="(bankAccountData.id) ? 'Update' : 'Add'"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    @ok="validateForm"
  >
    <validation-observer ref="newTaxiBankAccountForm">
      <b-form id="add-new-taxi-bank-account">
        <validation-provider
          #default="{ errors }"
          name="Bank Name"
          rules="required"
        >
          <b-form-group
            label="Bank Name"
            label-for="bank-name"
          >
            <b-form-input
              id="bank-name"
              v-model="bankAccountData.bankName"
              placeholder="Bank Name"
              :state="errors.length > 0 ? false : null"
              name="bank-name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Sort Code"
          rules="required"
        >
          <b-form-group
            label="Sort Code"
            label-for="sort-code"
          >
            <b-form-input
              id="sort-code"
              v-model="bankAccountData.bankSortCode"
              placeholder="Sort Code"
              :state="errors.length > 0 ? false : null"
              name="sort-code"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Account Number"
          rules="required"
        >
          <b-form-group
            label="Account Number"
            label-for="account-number"
          >
            <b-form-input
              id="account-number"
              v-model="bankAccountData.bankAccountNum"
              placeholder="Account Number"
              :state="errors.length > 0 ? false : null"
              name="account-number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Is Active"
          rules="required"
        >
          <b-form-group
            label="Is Active"
            label-for="is-active"
          >
            <v-select
              v-model="bankAccountData.isActive"
              :options="[{ label: 'Yes', value: true }, { label: 'No', value: false }]"
              label="label"
              :reduce="(options) => options.value"
              input-id="is-active"
            >
              <template #option="{ label, value }">
                <div
                  class="rounded-circle d-inline-block mr-50"
                  :class="(value) ? 'bg-success' : 'bg-secondary'"
                  style="height: 10px; width: 10px"
                />
                <span> {{ label }}</span>
              </template>

              <template #selected-option="{ label, value }">
                <div
                  class="rounded-circle d-inline-block mr-50"
                  :class="(value) ? 'bg-success' : 'bg-secondary'"
                  style="height: 10px; width: 10px"
                />
                <span> {{ label }}</span>
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Account Type"
          rules="required"
        >

          <b-form-group
            label="Account Type"
            label-for="account-type"
          >
            <v-select
              v-model="bankAccountData.isMain"
              :options="[{ label: 'Main', value: true }, { label: 'Secondary', value: false }]"
              label="label"
              :reduce="(options) => options.value"
              input-id="account-type"
            >
              <template #option="{ label, value }">
                <div
                  class="rounded-circle d-inline-block mr-50"
                  :class="(value) ? 'bg-primary' : 'bg-warning'"
                  style="height: 10px; width: 10px"
                />
                <span> {{ label }}</span>
              </template>

              <template #selected-option="{ label, value }">
                <div
                  class="rounded-circle d-inline-block mr-50"
                  :class="(value) ? 'bg-primary' : 'bg-warning'"
                  style="height: 10px; width: 10px"
                />
                <span> {{ label }}</span>
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
import { ref, watch } from "@vue/composition-api";
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import router from '@/router';
import useTaxiBankAccount from '@/modules/taxi/composables/useTaxiBankAccount';
import vSelect from 'vue-select';

export default {
  props: {
    bankAccount: {
      type: Object
    }
  },
  setup(props, ctx) {
    const { createBankAccount, editBankAccount } = useTaxiBankAccount();

    const newTaxiBankAccountForm = ref(null);
    const refModal = ref(null);
    const bankAccountData = ref({ ...props.bankAccount });

    watch(() => props.bankAccount, (value, _) => { bankAccountData.value = value; });

    const validateForm = (modalEvt) => {
      modalEvt.preventDefault();

      newTaxiBankAccountForm.value.validate().then(async (valid) => {
        if (!valid) return;

        if (bankAccountData.value.id === null) {
          const account = { ...bankAccountData.value, taxiId: router.currentRoute.params.id };
          const response = await createBankAccount(account);

          refModal.value.hide();

          if (response !== null) {
            bankAccountData.value = { ...response };
            ctx.emit('form-submitted', { ...bankAccountData.value });
          }

        } else {
          const response = await editBankAccount(bankAccountData.value);
          refModal.value.hide();

          (response !== null) && ctx.emit('form-submitted', { ...bankAccountData.value });
        }
      });
    };

    return {
      // Reactive
      newTaxiBankAccountForm,
      refModal,
      bankAccountData,

      // Method
      validateForm,

      // Validator
      required
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-taxi-bank-account {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>