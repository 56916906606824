<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Vehicle</b-card-title>
    </b-card-header>

    <b-card-body>
      <template v-if="vehiclesArray.length && vehicleData.id === null">
        <b-alert
          variant="warning"
          show
        >
          <div class="alert-body">
            <span><strong>Oops!</strong> It seems that no car is the main.</span>
          </div>
        </b-alert>
      </template>
      <template v-else>
        <b-row class="pb-1">
          <b-col>
            <span class="font-weight-bold">ManuFacturer</span>
          </b-col>
          <b-col class="text-right">
            {{ vehicleData.manufacturer }}
          </b-col>
        </b-row>
        <b-row class="pb-1">
          <b-col>
            <span class="font-weight-bold">Model</span>
          </b-col>
          <b-col class="text-right">
            {{ vehicleData.model }}
          </b-col>
        </b-row>
        <b-row class="pb-1">
          <b-col>
            <span class="font-weight-bold">Year</span>
          </b-col>
          <b-col class="text-right">
            {{ vehicleData.year }}
          </b-col>
        </b-row>
        <b-row class="pb-1">
          <b-col>
            <span class="font-weight-bold">Plate</span>
          </b-col>
          <b-col class="text-right">
            {{ vehicleData.regNum }}
          </b-col>
        </b-row>
        <b-row class="pb-1">
          <b-col>
            <span class="font-weight-bold">Seats</span>
          </b-col>
          <b-col class="text-right">
            {{ vehicleData.numSeats }}
          </b-col>
        </b-row>
        <b-row class="pb-1 mb-md-3">
          <b-col>
            <span class="font-weight-bold">Wheelchair</span>
          </b-col>
          <b-col
            class="text-right"
            v-if="vehicleData.wheelchairFriendly !== null"
          >
            <b-badge
              :variant="(vehicleData.wheelchairFriendly) ? 'light-success' : 'light-secondary'"
              class="text-capitalize"
            >
              {{ (vehicleData.wheelchairFriendly) ? 'Yes' : 'No' }}
            </b-badge>
          </b-col>
        </b-row>
      </template>

      <div class="btn-wrapper-card">
        <b-button
          v-if="vehiclesArray.length"
          block
          variant="primary"
          @click="openListModal"
        >
          Manage
        </b-button>
        <b-button
          v-else
          block
          variant="primary"
          @click="openFormModal"
        >
          Add
        </b-button>
      </div>
    </b-card-body>

    <taxi-vehicle-list-modal
      v-if="vehiclesArray.length"
      ref="vehicleListRef"
    />

    <taxi-vehicle-modal
      v-else
      :vehicle="vehicleData"
      ref="vehicleFormRef"
    />

  </b-card>
</template>

<script>
import { BAlert, BBadge, BButton, BCard, BCardBody, BCardHeader, BCardTitle, BCol, BRow } from 'bootstrap-vue';
import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import TaxiVehicleListModal from "@/modules/taxi/components/TaxiVehicleListModal.vue";
import TaxiVehicleModal from '@/modules/taxi/components/TaxiVehicleModal.vue';

export default {
  setup() {
    const vehicleFormRef = ref(null);
    const vehicleListRef = ref(null);

    const vehicleData = computed(() => store.getters['taxiVehicle/mainVehicle']);
    const vehiclesArray = computed(() => store.getters['taxiVehicle/vehicles']);

    const openFormModal = () => { vehicleFormRef.value.$refs.refModal.show(); };
    const openListModal = () => { vehicleListRef.value.$refs.refModal.show(); };

    return {
      // Reactive
      vehicleFormRef,
      vehicleListRef,

      // Computed
      vehicleData,
      vehiclesArray,

      // Method
      openFormModal,
      openListModal,
    };
  },
  components: {
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCol,
    BRow,
    TaxiVehicleListModal,
    TaxiVehicleModal,
  },
};
</script>

<style scoped>
.btn-wrapper-card {
  bottom: 1.5rem;
  position: absolute;
  width: calc(100% - 3rem);
}

@media(max-width: 425px) {
  .btn-wrapper-card {
    bottom: 0;
    position: relative;
    width: 100%;
  }
}
</style>