var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"refModal",attrs:{"id":"taxi-jura-card-modal","title":"JURA Card","ok-title":(_vm.juraCardData.id)? 'Update': 'Add',"no-close-on-backdrop":"","cancel-variant":"outline-secondary"},on:{"ok":_vm.handleOk,"hidden":_vm.resetCard}},[(_vm.juraCardData.id)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"outline-danger"},on:{"click":_vm.removeCard}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1):_vm._e(),_c('validation-observer',{ref:"newTaxiJuraCardForm"},[_c('b-form',{attrs:{"id":"add-new-taxi-jura-card"},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm();}}},[_c('validation-provider',{attrs:{"name":"Card Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Card Number","label-for":"card-number"}},[_c('b-form-input',{attrs:{"id":"card-number","type":"number","placeholder":"Card Number","state":errors.length > 0 ? false : null,"name":"card-number"},model:{value:(_vm.juraCardData.juraCardNum),callback:function ($$v) {_vm.$set(_vm.juraCardData, "juraCardNum", $$v)},expression:"juraCardData.juraCardNum"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Is Active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Is Active","label-for":"is-active"}},[_c('v-select',{attrs:{"options":[{label: 'Yes', value: true},{label: 'No', value: false}],"label":"label","reduce":function (options) { return options.value; },"input-id":"is-active"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.juraCardData.isActive),callback:function ($$v) {_vm.$set(_vm.juraCardData, "isActive", $$v)},expression:"juraCardData.isActive"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }