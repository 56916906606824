import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useTaxiBankAccount = () => {
  const toast = useToast();

  const createBankAccount = async (bankAccount) => {
    try {
      return await store.dispatch('taxiBankAccount/create', bankAccount);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error creating the taxi bank account',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };
  const deleteBankAccount = async (bankAccount) => {
    try {
      await store.dispatch('taxiBankAccount/remove', bankAccount);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error deleting the taxi bank account',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };
  const editBankAccount = async (bankAccount) => {
    try {
      await store.dispatch('taxiBankAccount/update', bankAccount);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating the taxi bank account',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const getTaxiAccounts = async (taxiId) => {
    try {
      return await store.dispatch('taxiBankAccount/getAccountByTaxi', taxiId);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the taxi accounts',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };
  return {
    createBankAccount,
    deleteBankAccount,
    editBankAccount,
    getTaxiAccounts,
  };
};

export default useTaxiBankAccount;
