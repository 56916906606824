var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"refModal",attrs:{"id":"taxi-standing-order-form-modal","title":"Standing Order Details","ok-title":(_vm.standingOrderData.id) ? 'Update' : 'Add',"no-close-on-backdrop":"","cancel-variant":"outline-secondary"},on:{"ok":_vm.validateForm}},[_c('validation-observer',{ref:"newTaxiStandingOrderForm"},[_c('b-form',{attrs:{"id":"add-new-taxi-standing-order"}},[_c('validation-provider',{attrs:{"name":"Standing Order Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Standing Order Type","label-for":"standing-order-type"}},[_c('v-select',{attrs:{"options":_vm.standingOrderTypes,"label":"name","reduce":function (options) { return options.id; },"input-id":"standing-order-type"},model:{value:(_vm.standingOrderData.typeId),callback:function ($$v) {_vm.$set(_vm.standingOrderData, "typeId", $$v)},expression:"standingOrderData.typeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Amount","label-for":"amount"}},[_c('b-input-group',{staticClass:"input-group",attrs:{"prepend":"£"}},[_c('b-form-input',{attrs:{"id":"amount","type":"number","step":".01","max":"10000","min":"-10000","placeholder":"Amount","state":errors.length > 0 ? false : null,"name":"amount"},model:{value:(_vm.standingOrderData.amount),callback:function ($$v) {_vm.$set(_vm.standingOrderData, "amount", $$v)},expression:"standingOrderData.amount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Frequency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Frequency","label-for":"frequency"}},[_c('v-select',{attrs:{"options":_vm.frequencyList,"label":"label","reduce":function (options) { return options.value; },"input-id":"frequency"},on:{"input":function (option) { return _vm.frequencySelected(option); }},model:{value:(_vm.frequencyValue),callback:function ($$v) {_vm.frequencyValue=$$v},expression:"frequencyValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Is Active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Is Active","label-for":"is-active"}},[_c('v-select',{attrs:{"options":[{ label: 'Yes', value: true }, { label: 'No', value: false }],"label":"label","reduce":function (options) { return options.value; },"input-id":"is-active"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-primary' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-primary' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.standingOrderData.isActive),callback:function ($$v) {_vm.$set(_vm.standingOrderData, "isActive", $$v)},expression:"standingOrderData.isActive"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Date From","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Date From","label-for":"date-to"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ dateFormat: 'd/m/Y', enableTime: false }},model:{value:(_vm.standingOrderData.startDateUtc),callback:function ($$v) {_vm.$set(_vm.standingOrderData, "startDateUtc", $$v)},expression:"standingOrderData.startDateUtc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }