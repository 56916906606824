import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

const useStandingOrderType = () => {
  const toast = useToast();

  const createStandingOrderType = async (standingOrderType) => {
    try {
      await store.dispatch('standingOrderType/create', standingOrderType);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error creating the standing order type',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const editStandingOrderType = async (standingOrderType) => {
    try {
      await store.dispatch('standingOrderType/update', standingOrderType);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating the standing order type',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getStandingOrderList = async (params = {}) => {
    try {
      return await store.dispatch('standingOrderType/getList', params);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error fetching standing order type list',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    // Method
    createStandingOrderType,
    editStandingOrderType,
    getStandingOrderList,
  };
};

export default useStandingOrderType;
