<template>
  <b-modal
    ref="refModal"
    id="taxi-standing-order-form-modal"
    title="Standing Order Details"
    :ok-title="(standingOrderData.id) ? 'Update' : 'Add'"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    @ok="validateForm"
  >
    <validation-observer ref="newTaxiStandingOrderForm">
      <b-form id="add-new-taxi-standing-order">
        <validation-provider
          #default="{ errors }"
          name="Standing Order Type"
          rules="required"
        >
          <b-form-group
            label="Standing Order Type"
            label-for="standing-order-type"
          >
            <v-select
              v-model="standingOrderData.typeId"
              :options="standingOrderTypes"
              label="name"
              :reduce="(options) => options.id"
              input-id="standing-order-type"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Amount"
          rules="required"
        >
          <b-form-group
            label="Amount"
            label-for="amount"
          >
            <b-input-group
              prepend="£"
              class="input-group"
            >
              <b-form-input
                id="amount"
                v-model="standingOrderData.amount"
                type="number"
                step=".01"
                max="10000"
                min="-10000"
                placeholder="Amount"
                :state="errors.length > 0 ? false : null"
                name="amount"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Frequency"
          rules="required"
        >
          <b-form-group
            label="Frequency"
            label-for="frequency"
          >
            <v-select
              v-model="frequencyValue"
              :options="frequencyList"
              label="label"
              :reduce="(options) => options.value"
              input-id="frequency"
              @input="(option) => frequencySelected(option)"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Is Active"
          rules="required"
        >
          <b-form-group
            label="Is Active"
            label-for="is-active"
          >
            <v-select
              v-model="standingOrderData.isActive"
              :options="[{ label: 'Yes', value: true }, { label: 'No', value: false }]"
              label="label"
              :reduce="(options) => options.value"
              input-id="is-active"
            >
              <template #option="{ label, value }">
                <div
                  class="rounded-circle d-inline-block mr-50"
                  :class="(value) ? 'bg-primary' : 'bg-secondary'"
                  style="height: 10px; width: 10px"
                />
                <span> {{ label }}</span>
              </template>

              <template #selected-option="{ label, value }">
                <div
                  class="rounded-circle d-inline-block mr-50"
                  :class="(value) ? 'bg-primary' : 'bg-secondary'"
                  style="height: 10px; width: 10px"
                />
                <span> {{ label }}</span>
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Date From"
          rules="required"
        >
          <b-form-group
            label="Date From"
            label-for="date-to"
          >
            <flat-pickr
              v-model="standingOrderData.startDateUtc"
              class="form-control"
              :config="{ dateFormat: 'd/m/Y', enableTime: false }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput, BInputGroup } from 'bootstrap-vue';
import { ref, watch } from "@vue/composition-api";
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import useTaxiStandingOrder from '@/modules/taxi/composables/useTaxiStandingOrder';
import vSelect from 'vue-select';
import useUtils from '@/composables/useUtils';
import useStandingOrderType from '@/modules/standing-order-type/composables/useStandingOrderType';

export default {
  props: {
    standingOrder: {
      type: Object
    }
  },
  setup(props, ctx) {
    const { convertDateToUTC, formatDateDdMmYyyy } = useUtils();
    const { createStandingOrder, editStandingOrder } = useTaxiStandingOrder();
    const { getStandingOrderList } = useStandingOrderType();

    const frequencyValue = ref(null);
    const newTaxiStandingOrderForm = ref(null);
    const refModal = ref(null);
    const standingOrderTypes = ref([]);
    const standingOrderData = ref({ ...props.standingOrder });
    const frequencyList = [
      { label: 'Daily', value: 'isDaily' },
      { label: 'Weekly', value: 'isWeekly' },
      { label: 'Monthly', value: 'isMonthly' },
      { label: 'Yearly', value: 'isYearly' },
      { label: 'One Off', value: 'isOneOff' }
    ];

    watch(() => props.standingOrder, (value, _) => {
      frequencyValue.value = getFrequencyValue(value);
      standingOrderData.value = value;
      standingOrderData.value.startDateUtc = value.startDateUtc === null ? new Date() : formatDateDdMmYyyy(value.startDateUtc);
    });

    const validateForm = (modalEvt) => {
      modalEvt.preventDefault();

      newTaxiStandingOrderForm.value.validate().then(async (valid) => {
        if (!valid) return;

        const standingOrder = { ...standingOrderData.value, startDateUtc: convertDateToUTC(standingOrderData.value.startDateUtc) };

        const response = (standingOrderData.value.id === null)
          ? await createStandingOrder(standingOrder)
          : await editStandingOrder(standingOrder);

        if (response !== null) {
          ctx.emit('form-submitted');
          refModal.value.hide();
        }
      });
    };

    const getFrequencyValue = (data) => {
      if (data.isDaily) return 'isDaily';
      if (data.isWeekly) return 'isWeekly';
      if (data.isMonthly) return 'isMonthly';
      if (data.isYearly) return 'isYearly';
      if (data.isOneOff) return 'isOneOff';
    }

    const frequencySelected = (data) => {
      standingOrderData.value.isDaily = false;
      standingOrderData.value.isWeekly = false;
      standingOrderData.value.isMonthly = false;
      standingOrderData.value.isYearly = false;
      standingOrderData.value.isOneOff = false;

      if (data === 'isDaily') standingOrderData.value.isDaily = true
      if (data === 'isWeekly') standingOrderData.value.isWeekly = true
      if (data === 'isMonthly') standingOrderData.value.isMonthly = true
      if (data === 'isYearly') standingOrderData.value.isYearly = true
      if (data === 'isOneOff') standingOrderData.value.isOneOff = true
    }

    getStandingOrderList().then((data) => {
      standingOrderTypes.value = data.standingOrderTypes
    })


    return {
      // Reactive
      newTaxiStandingOrderForm,
      refModal,
      standingOrderData,
      standingOrderTypes,
      frequencyList,
      frequencyValue,

      // Method
      frequencySelected,
      validateForm,

      // Validator
      required
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

@import '@core/scss/vue/libs/vue-select.scss';

#add-new-taxi-standing-order-form {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>