<template>
  <b-card
    :img-src="require('@/assets/images/banner/banner-12.jpg')"
    img-alt="Profile Cover Photo"
    img-top
    class="card-profile"
  >
    <div class="profile-image-wrapper">
      <div class="profile-image p-0">
        <b-avatar
          size="114"
          variant="light-secondary"
          :text="avatarText(`${taxi.firstName} ${taxi.lastName}`)"
        />
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <b-dropdown
        class="jura-card"
        variant="link"
        no-caret
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item @click="openJuraCardModal">
          <feather-icon
            icon="CreditCardIcon"
            class="mr-50 align-middle"
          />
          <span class="align-middle">Edit JURA Card</span>
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="!juraCard.id || !juraCashlessCard"
          @click="openJuraCardFromToAddMonenyModal"
        >
          <feather-icon
            icon="CreditCardIcon"
            class="mr-50 align-middle"
          />
          <span class="align-middle">Add Money to JURA Card</span>
        </b-dropdown-item>
        <b-dropdown-item @click="openAccountStatementModal">
          <feather-icon icon="ListIcon" />
          <span class="align-middle ml-50">Acc. Statement</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <h3>{{ `${taxi.firstName} ${taxi.lastName}` }}</h3>
      <b-badge
        :variant="taxi.isMember ? 'light-primary' : 'light-secondary'"
        class="profile-badge ml-75"
      >
        <feather-icon
          icon="UserIcon"
          class="mr-25 b-badge-icon"
        />
        {{ taxi.isMember ? 'Member' : 'Non Member' }}
      </b-badge>
    </div>

    <h6 class="text-muted">{{ taxi.licenceNum }}</h6>
    <h6 class="text-muted">{{ taxi.email }}</h6>
    <h6 class="text-muted">{{ taxi.contactTel }}</h6>

    <b-badge
      :variant="taxi.isInRadioShift ? 'light-primary' : 'light-secondary'"
      class="profile-badge"
    >
      <feather-icon
        icon="RadioIcon"
        class="mr-25 b-badge-icon"
      />
      {{ taxi.isInRadioShift ? 'Radio Shifts' : 'No Radio Shifts' }}
    </b-badge>

    <div
      v-if="juraCard.juraCardNum !== null && juraCard.isActive !== null"
      class="d-flex align-items-center justify-content-center"
    >
      <b-badge
        variant="light-primary"
        class="profile-badge mr-50"
        v-b-tooltip.hover.v-primary
        title="JURA card number"
      >
        <feather-icon
          icon="CreditCardIcon"
          class="mr-25 b-badge-icon"
        />
        {{ juraCard.juraCardNum }}
      </b-badge>
      <b-badge
        :variant="juraCard.isActive ? 'light-success' : 'light-secondary'"
        class="profile-badge ml-50"
      >
        <feather-icon
          icon="CheckSquareIcon"
          class="mr-25 b-badge-icon"
        />
        {{ juraCard.isActive ? 'Active' : 'Disbled' }}
      </b-badge>
    </div>

    <hr class="mb-2" />

    <div class="d-flex justify-content-center align-items-center">
      <div>
        <h6 class="text-muted font-weight-bolder">Balance</h6>
        <h3 class="mb-0">
          {{ taxiBalance }}
        </h3>
      </div>
    </div>
    <taxi-jura-card-modal
      ref="juraCardModalRef"
      :juraCard="juraCard"
      @form-submitted="taxiJuraCardFormSubmitted"
    />
    <taxi-jura-card-form-to-add-money-modal
      v-if="juraCashlessCard"
      ref="juraCardFormToAddMoneyModalRef"
      :juraCard="juraCashlessCard"
      :taxiId="juraCard.taxiId"
      @form-submitted="taxiJuraCardFormToAddMoneySubmitted"
    />
    <taxi-account-statement-modal ref="accountStatementModalRef" />
  </b-card>
</template>

<script>
import { avatarText } from '@core/utils/filter';
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BRow,
  VBTooltip,
} from 'bootstrap-vue';
import { computed, ref, onMounted } from '@vue/composition-api';
import TaxiAccountStatementModal from '@/modules/taxi/components/TaxiAccountStatementModal.vue';
import TaxiJuraCardModal from '@/modules/taxi/components/TaxiJuraCardModal.vue';
import TaxiJuraCardFormToAddMoneyModal from '@/modules/taxi/components/TaxiJuraCardFormToAddMoneyModal.vue';
import useTaxi from '@/modules/taxi/composables/useTaxi';
import store from '@/store';
import useUtils from '@/composables/useUtils';
import useTaxiJuraCard from '../composables/useTaxiJuraCard';
import useTaxiTransaction from '../composables/useTaxiTransaction';

export default {
  props: {
    taxi: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const { deleteTaxi } = useTaxi();
    const { formatCurrency } = useUtils();
    const { getJuraCashlessCardDetails } = useTaxiJuraCard();
    const { fetchTaxiTrasactions } = useTaxiTransaction();

    const juraCardModalRef = ref(null);
    const juraCardFormToAddMoneyModalRef = ref(null);
    const accountStatementModalRef = ref(null);
    const taxiBalance = computed(() =>
      formatCurrency(store.getters['taxi/getTaxiBalance'])
    );
    const juraCashlessCard = ref();

    const juraCard =
      props.taxi.juraCard === null
        ? ref({
            id: null,
            taxiId: props.taxi.id,
            juraCardNum: null,
            isActive: null,
          })
        : ref({ ...props.taxi.juraCard });

    onMounted(async () => {
      if (juraCard.value.juraCardNum !== null) {
        juraCashlessCard.value = await getJuraCashlessCardDetails(
          juraCard.value.juraCardNum
        );
      }
    });

    const removeTaxi = async () => {
      await deleteTaxi(props.taxi);
    };

    const openJuraCardModal = () => {
      juraCardModalRef.value.$refs.refModal.show();
    };

    const openJuraCardFromToAddMonenyModal = () => {
      juraCardFormToAddMoneyModalRef.value.$refs.refModal.show();
    };

    const openAccountStatementModal = () => {
      accountStatementModalRef.value.$refs.refModal.show();
    };

    const taxiJuraCardFormSubmitted = async (data) => {
      juraCard.value = { ...data };
      juraCashlessCard.value = await getJuraCashlessCardDetails(
        juraCard.value.juraCardNum
      );
    };

    const taxiJuraCardFormToAddMoneySubmitted = async () => {
      juraCashlessCard.value = undefined;
      const [juraCashlessCardData, _] = await Promise.all([
        getJuraCashlessCardDetails(juraCard.value.juraCardNum),
        fetchTaxiTrasactions(props.taxi.id, { pageSize: 9, pageNumber: 1 }),
      ]);
      juraCashlessCard.value = juraCashlessCardData;

      ctx.emit('reload-taxi-data');
    };

    return {
      // Reactive
      taxiBalance,
      juraCard,
      juraCardModalRef,
      juraCardFormToAddMoneyModalRef,
      accountStatementModalRef,
      juraCashlessCard,

      // Method
      removeTaxi,
      taxiJuraCardFormSubmitted,
      taxiJuraCardFormToAddMoneySubmitted,
      openJuraCardModal,
      openJuraCardFromToAddMonenyModal,
      openAccountStatementModal,

      // Filter
      avatarText,
    };
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BRow,
    TaxiAccountStatementModal,
    TaxiJuraCardModal,
    TaxiJuraCardFormToAddMoneyModal,
    VBTooltip,
  },
};
</script>

<style scoped>
.card-profile .card-body {
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.jura-card {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.b-badge-icon {
  height: 13px;
  width: 13px;
}
</style>
