<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Bank Account</b-card-title>
    </b-card-header>

    <b-card-body>
      <template v-if="accountsArray.length && accountData.id === null">
        <b-alert
          variant="warning"
          show
        >
          <div class="alert-body">
            <span><strong>Oops!</strong> It seems that no account is the main.</span>
          </div>
        </b-alert>
      </template>
      <template v-else>
        <b-row class="mb-1">
          <b-col>
            <span class="font-weight-bold">Bank</span>
          </b-col>
          <b-col class="text-right">
            {{ accountData.bankName }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col>
            <span class="font-weight-bold">Sort Code</span>
          </b-col>
          <b-col class="text-right">
            {{ accountData.bankSortCode }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col>
            <span class="font-weight-bold">Account</span>
          </b-col>
          <b-col class="text-right">
            {{ accountData.bankAccountNum }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col>
            <span class="font-weight-bold">Is Active</span>
          </b-col>
          <b-col
            v-if="accountData.isActive !== null"
            class="text-right"
          >
            <b-badge
              pill
              :variant="(accountData.isActive) ? 'light-success' : 'light-secondary'"
              class="text-capitalize"
            >
              {{ (accountData.isActive) ? 'Yes' : 'No' }}
            </b-badge>
          </b-col>
        </b-row>

      </template>

      <div class="btn-wrapper-card">
        <b-button
          v-if="accountsArray.length"
          block
          variant="primary"
          @click="openListModal"
        >
          Manage
        </b-button>
        <b-button
          v-else
          block
          variant="primary"
          @click="openFormModal"
        >
          Add
        </b-button>
      </div>
    </b-card-body>

    <taxi-account-list-modal
      v-if="accountsArray.length"
      ref="accountListRef"
    />

    <taxi-bank-account-modal
      v-else
      ref="accountFormRef"
      :bankAccount="accountData"
    />
  </b-card>
</template>

<script>
import { BAlert, BBadge, BButton, BCard, BCardBody, BCardHeader, BCardTitle, BCol, BRow } from 'bootstrap-vue';
import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import TaxiAccountListModal from '@/modules/taxi/components/TaxiAccountListModal.vue';
import TaxiBankAccountModal from '@/modules/taxi/components/TaxiBankAccountModal.vue';

export default {
  setup() {
    const accountFormRef = ref(null);
    const accountListRef = ref(null);

    const accountData = computed(() => store.getters['taxiBankAccount/mainAccount']);
    const accountsArray = computed(() => store.getters['taxiBankAccount/accounts']);

    const openFormModal = () => { accountFormRef.value.$refs.refModal.show(); };
    const openListModal = () => { accountListRef.value.$refs.refModal.show(); };

    return {
      // Reactive
      accountFormRef,
      accountListRef,

      // Computed
      accountData,
      accountsArray,

      // Method
      openFormModal,
      openListModal
    };
  },
  components: {
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCol,
    BRow,
    TaxiBankAccountModal,
    TaxiAccountListModal
  },
};
</script>

<style scoped>
.btn-wrapper-card {
  bottom: 1.5rem;
  position: absolute;
  width: calc(100% - 3rem);
}

@media(max-width: 425px) {
  .btn-wrapper-card {
    bottom: 0;
    position: relative;
    width: 100%;
  }
}
</style>
