<template>
  <div class="d-flex flex-row justify-content-end mb-1">
    <b-dropdown
      variant="link"
      no-caret
      toggle-class="p-0"
      right
    >

      <template #button-content>
        <b-button
          variant="primary"
          class="btn-icon"
        >
          <feather-icon icon="SettingsIcon" />
        </b-button>
      </template>

      <b-dropdown-item @click="openTaxiStandingOrderModal">
        <feather-icon
          icon="PocketIcon"
          size="16"
        />
        <span class="align-middle ml-50">Standing Order</span>
      </b-dropdown-item>
    </b-dropdown>
    <taxi-standing-order-list-modal ref="taxiStandingOrderModalRef" />
  </div>
</template>

<script>
import { BButton, BDropdown, BDropdownItem } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import TaxiStandingOrderListModal from '@/modules/taxi/components/modals/TaxiStandingOrderListModal.vue';

export default {
  setup() {
    const taxiStandingOrderModalRef = ref(null);

    const openTaxiStandingOrderModal = () => {
      taxiStandingOrderModalRef.value.$refs.refModal.show();
    }

    return {
      // Reactive
      taxiStandingOrderModalRef,

      // Method
      openTaxiStandingOrderModal
    };
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    TaxiStandingOrderListModal
  }
};
</script>