<template>
  <b-modal
    ref="refModal"
    id="taxi-jura-card-modal"
    title="JURA Card"
    :ok-title="(juraCardData.id)? 'Update': 'Add'"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    @ok="handleOk"
    @hidden="resetCard"
  >
    <div
      class="d-flex justify-content-end"
      v-if="juraCardData.id"
    >
      <b-button
        variant="outline-danger"
        class="btn-icon"
        @click="removeCard"
      >
        <feather-icon icon="TrashIcon" />
      </b-button>
    </div>

    <validation-observer ref="newTaxiJuraCardForm">
      <b-form
        id="add-new-taxi-jura-card"
        @submit.prevent="validateForm();"
      >
        <validation-provider
          #default="{errors}"
          name="Card Number"
          rules="required"
        >
          <b-form-group
            label="Card Number"
            label-for="card-number"
          >
            <b-form-input
              id="card-number"
              v-model="juraCardData.juraCardNum"
              type="number"
              placeholder="Card Number"
              :state="errors.length > 0 ? false : null"
              name="card-number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{errors}"
          name="Is Active"
          rules="required"
        >
          <b-form-group
            label="Is Active"
            label-for="is-active"
          >
            <v-select
              v-model="juraCardData.isActive"
              :options="[{label: 'Yes', value: true},{label: 'No', value: false}]"
              label="label"
              :reduce="(options) => options.value"
              input-id="is-active"
            >
              <template #option="{ label, value }">
                <div
                  class="rounded-circle d-inline-block mr-50"
                  :class="(value) ? 'bg-success' : 'bg-secondary'"
                  style="height: 10px; width: 10px"
                />
                <span> {{ label }}</span>
              </template>

              <template #selected-option="{ label, value }">
                <div
                  class="rounded-circle d-inline-block mr-50"
                  :class="(value) ? 'bg-success' : 'bg-secondary'"
                  style="height: 10px; width: 10px"
                />
                <span> {{ label }}</span>
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
import { ref } from "@vue/composition-api";
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Swal from 'sweetalert2';
import useTaxiJuraCard from '@/modules/taxi/composables/useTaxiJuraCard';
import vSelect from 'vue-select';
import router from '@/router';

export default {
  props: {
    juraCard: {
      type: Object
    }
  },
  setup(props, ctx) {
    const { createJuraCard, deleteJuraCard, editJuraCard } = useTaxiJuraCard();
    const newTaxiJuraCardForm = ref(null);
    const refModal = ref(null);
    const juraCardData = ref({ ...props.juraCard });

    const handleOk = (modalEvt) => {
      modalEvt.preventDefault();
      validateForm();
    };

    const removeCard = async () => {
      const { value } = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      const response = await deleteJuraCard(juraCardData.value);

      refModal.value.hide();

      if (response !== null) {
        juraCardData.value = {
          id: null,
          taxiId: router.currentRoute.params.id,
          juraCardNum: null,
          isActive: null
        };
        ctx.emit('form-submitted', juraCardData.value);
      }

    };

    const resetCard = () => {
      juraCardData.value = { ...props.juraCard };
    };

    const validateForm = () => {
      newTaxiJuraCardForm.value.validate().then(async (valid) => {
        if (!valid) return;

        if (juraCardData.value.id === null) {
          const response = await createJuraCard(juraCardData.value);

          refModal.value.hide();

          if (response !== null) {
            juraCardData.value = { ...response };
            ctx.emit('form-submitted', { ...juraCardData.value });
          }

        } else {
          const response = await editJuraCard(juraCardData.value);
          refModal.value.hide();

          (response !== null) && ctx.emit('form-submitted', { ...juraCardData.value });
        }

      });
    };

    return {
      // Reactive
      newTaxiJuraCardForm,
      refModal,
      juraCardData,

      // Method
      handleOk,
      removeCard,
      resetCard,

      // Validator
      required
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-taxi-jura-card {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>