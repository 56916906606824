import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useTaxiJuraCard = () => {
  const toast = useToast();

  const createJuraCard = async (juraCard) => {
    try {
      return await store.dispatch('taxiJuraCard/create', juraCard);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error creating the taxi JURA card',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const addCreditToJuraCard = async (taxiId, cardNum, amount) => {
    try {
      return await store.dispatch('taxiJuraCard/addCredit', {
        taxiId,
        cardNum,
        amount,
      });
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error adding credit to JURA card',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const getJuraCashlessCardDetails = async (cardNum) => {
    try {
      return await store.dispatch(
        'taxiJuraCard/getJuraCashlessCardDetails',
        cardNum
      );
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error fetching JURA card details',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const deleteJuraCard = async (juraCard) => {
    try {
      await store.dispatch('taxiJuraCard/remove', juraCard.id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error deleting the taxi JURA card',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const editJuraCard = async (juraCard) => {
    try {
      await store.dispatch('taxiJuraCard/update', juraCard);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating the taxi JURA card',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  return {
    // Method
    createJuraCard,
    deleteJuraCard,
    editJuraCard,
    addCreditToJuraCard,
    getJuraCashlessCardDetails,
  };
};

export default useTaxiJuraCard;
