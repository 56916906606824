<template>
  <b-card
    no-body
    class="card-payment"
  >
    <b-card-header>
      <b-card-title>Taxi Balance</b-card-title>
      <b-card-title class="text-primary">
        {{ taxiBalance }}
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row class="pb-75">
        <b-col>
          <b-form-radio
            v-model="chargeTransaction"
            name="charge-transaction"
            :value="false"
          >
            Debit
          </b-form-radio>
        </b-col>

        <b-col>
          <b-form-radio
            v-model="chargeTransaction"
            name="charge-transaction"
            :value="true"
          >
            Credit
          </b-form-radio>
        </b-col>
      </b-row>
      <validation-observer ref="newTransactionPayoutForm">
        <b-form
          id="add-new-transaction-payout"
          class="h-100"
          @submit.prevent="validateForm()"
        >
          <validation-provider
            #default="{ errors }"
            name="Amount"
            rules="required"
          >
            <b-form-group
              label="Amount"
              label-for="amount"
            >
              <b-input-group
                prepend="£"
                class="input-group-merge"
              >
                <b-form-input
                  id="amount"
                  v-model="transactionData.amount"
                  type="number"
                  step=".01"
                  max="1000000"
                  min="0"
                  placeholder="Amount"
                  :state="errors.length > 0 ? false : null"
                  name="amount"
                  @input="storePayoutAmount"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Transaction Type"
            rules="required"
          >
            <b-form-group
              label="Transaction Type"
              label-for="transaction-type"
            >
              <v-select
                v-model="transactionData.transactionTypeId"
                :options="transactionTypes"
                label="name"
                :reduce="(transactionType) => transactionType.id"
                input-id="transaction-type"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-form-group
            label="Taxi"
            label-for="taxi-list"
          >
            <v-select
              v-model="transactionData.taxiId"
              :options="taxiList"
              :reduce="(taxi) => taxi.id"
              input-id="taxi-list"
              label="licenceNum"
            >
              <template #option="{ firstName, lastName, licenceNum }">
                <span>
                  <span class="font-weight-bolder">{{ licenceNum }}</span>
                  -
                  <small>{{ `${firstName} ${lastName} ` }}</small>
                </span>
              </template>

              <template #selected-option="{ firstName, lastName, licenceNum }">
                <span>
                  <span class="font-weight-bolder">{{ licenceNum }}</span>
                  -
                  <small>{{ `${firstName} ${lastName} ` }}</small>
                </span>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group
            label="Account"
            label-for="account-list"
          >
            <v-select
              v-model="transactionData.accountId"
              :options="accountList"
              label="name"
              :reduce="(account) => account.id"
              input-id="account-list"
            />
          </b-form-group>

          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-input
              id="description"
              v-model="transactionData.description"
              placeholder="Description"
              name="description"
            />
          </b-form-group>

          <sig-capt />

          <div class="btn-wrapper-card">
            <b-button
              block
              variant="primary"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                variant="light"
              />
              Payout
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BSpinner,
} from 'bootstrap-vue';
import { computed, ref } from '@vue/composition-api';
import { required } from '@validations';
import { useToast } from 'vue-toastification/composition';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import router from '@/router';
import SigCapt from '@/modules/taxi/components/signature/SigCapt.vue';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useAccount from '@/modules/account/composables/useAccount';
import useTaxi from '@/modules/taxi/composables/useTaxi';
import useTaxiTransaction from '@/modules/taxi/composables/useTaxiTransaction';
import vSelect from 'vue-select';
import useUtils from '@/composables/useUtils';

export default {
  setup() {
    const {
      CASH_ID,
      createTransaction,
      fetchTaxiTrasactions,
      fetchTransactionTypes,
    } = useTaxiTransaction();
    const { getTaxiList } = useTaxi();
    const { getAccountList } = useAccount();
    const { formatCurrency } = useUtils();
    const toast = useToast();

    const emptyTransaction = {
      amount: null,
      bookingId: null,
      description: null,
      isManualEntry: true,
      signature: null,
      id: router.currentRoute.params.id,
      taxiId: null,
      accountId: null,
      transactionTypeId: null,
    };

    const newTransactionPayoutForm = ref(null);
    const transactionData = ref({ ...emptyTransaction });
    const transactionTypes = ref([]);
    const taxiList = ref([]);
    const accountList = ref([]);
    const chargeTransaction = ref(false);
    const isLoading = ref(false);

    const taxiData = computed(() => store.getters['taxi/taxi']);
    
    const signatureData = computed(
      () => store.getters['taxi/getTaxiSignatureData']
    );
    
    const taxiBalance = computed(() =>
      formatCurrency(store.getters['taxi/getTaxiBalance'])
    );
    
    const validateForm = async () => {
      if (isLoading.value) return;
      isLoading.value = true;

      const valid = await newTransactionPayoutForm.value.validate();

      if (!valid) {
        isLoading.value = false;
        return;
      }

      if (
        !signatureData.value.signatureSrc &&
        !chargeTransaction.value &&
        transactionData.value.transactionTypeId === CASH_ID
      ) {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'Signature required',
            icon: 'XCircleIcon',
          },
        });

        isLoading.value = false;
        return;
      }

      const amount = !chargeTransaction.value
        ? transactionData.value.amount * -1
        : transactionData.value.amount;
      const signature = signatureData.value.signatureSrc;

      const transaction = {
        ...transactionData.value,
        amount,
        signature,
      };

      const newTransaction = await createTransaction(transaction);

      const newTaxiBalance = newTransaction.newBalance;
      const id = newTransaction.taxiId;
      const queryParams = { pageSize: 9, pageNumber: 1 };
      await fetchTaxiTrasactions(id, queryParams); // Refresh transaction list

      store.commit('taxi/storeTaxiBalance', newTaxiBalance);

      // Reset form
      transactionData.value = { ...emptyTransaction };
      store.commit('taxi/resetTaxiSignatureData');
      newTransactionPayoutForm.value.reset();
      isLoading.value = false;
    };

    const initData = async () => {
      const transactionTypesData = await fetchTransactionTypes();
      const { taxis } = await getTaxiList();
      const { accounts } = await getAccountList();
      accountList.value = accounts;
      taxiList.value = taxis;
      transactionTypes.value = transactionTypesData;
    };

    const storePayoutAmount = async (newAmount) => {
      store.commit('taxiTransaction/storePayoutAmount', newAmount);
    };

    initData();

    return {
      // Reactive
      accountList,
      chargeTransaction,
      newTransactionPayoutForm,
      taxiList,
      transactionData,
      transactionTypes,
      signatureData,
      isLoading,

      //Computed
      taxiData,
      taxiBalance,

      // Method
      validateForm,
      storePayoutAmount,

      // Validator
      required,
    };
  },
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCol,
    BForm,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormTextarea,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    SigCapt,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BSpinner,
  },
};
</script>

<style scoped>
.btn-wrapper-card {
  bottom: 1.5rem;
  position: absolute;
  width: calc(100% - 3rem);
}

@media (max-width: 425px) {
  .btn-wrapper-card {
    bottom: 0;
    position: relative;
    width: 100%;
  }
}
</style>
