import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useTaxiVehicle = () => {
  const toast = useToast();

  const createVehicle = async (vehicle) => {
    try {
      return await store.dispatch('taxiVehicle/create', vehicle);
    } catch (error) {
      console.error(error);
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error creating the taxi vehicle',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const deleteVehicle = async (vehicle) => {
    try {
      await store.dispatch('taxiVehicle/remove', vehicle);
    } catch (error) {
      console.error(error);
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error deleting the taxi vehicle',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const editVehicle = async (vehicle) => {
    try {
      await store.dispatch('taxiVehicle/update', vehicle);
    } catch (error) {
      console.error(error);
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating the taxi vehicle',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const getTaxiVehicles = async (taxiId) => {
    try {
      return await store.dispatch('taxiVehicle/getVehiclesByTaxi', taxiId);
    } catch (error) {
      console.error(error);
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the taxi vehicles',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  return {
    // Method
    createVehicle,
    deleteVehicle,
    editVehicle,
    getTaxiVehicles,
  };
};

export default useTaxiVehicle;
