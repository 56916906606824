import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useTaxiTransaction = () => {
  const BANK_TRANSFER_ID = 1;
  const CASH_ID = 2;

  const toast = useToast();

  const createTransaction = async (transaction) => {
    try {
      return await store.dispatch('taxiTransaction/create', transaction);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const fetchTransactionStatus = async () => {
    try {
      return await store.dispatch('taxiTransaction/getTransactionStatus');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error fetching the transaction status',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const fetchTransactionTypes = async () => {
    try {
      return await store.dispatch('taxiTransaction/getTransactionTypes');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error fetching the transaction types',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const fetchTransactions = async (params) => {
    try {
      return await store.dispatch('taxiTransaction/getTransactions', {
        params,
      });
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error fetching the transaction list',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const fetchTaxiTrasactions = async (taxiId, params) => {
    try {
      return await store.dispatch('taxiTransaction/getTransactionsByTaxi', {
        taxiId,
        params,
      });
    } catch (error) {
      console.error(error);
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error fetching the taxi transactions',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const downloadAccountStatement = async (taxiId, params) => {
    try {
      return await store.dispatch('taxi/downloadAccountStatement', {
        taxiId,
        params,
      });
    } catch (error) {
      const title =
        error.response.status === 404
          ? 'No transaction found in date range'
          : 'Error downloading the company account statement';
      toast.error({
        component: ToastificationContent,
        props: {
          title,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const sendAccountStatement = async (taxiId, params) => {
    try {
      await store.dispatch('taxi/sendAccountStatement', {
        taxiId,
        params,
      });

      toast.success({
        component: ToastificationContent,
        props: {
          title: 'Email sent successfully!',
          icon: 'CheckIcon',
        },
      });
      return true;
    } catch (error) {
      const title =
        error.response.status === 404
          ? 'No transaction found in date range'
          : error.response.status === 400
          ? 'Taxi email is empty'
          : 'Error sending the company account statement';
      toast.error({
        component: ToastificationContent,
        props: {
          title,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const cancelTransaction = async (transactionId, description) => {
    try {
      await store.dispatch(
        'taxiTransaction/cancelTransaction',
        {
          transactionId,
        description
      });
      return true;
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'An error occurred.',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const resolveTransactionStatusVariantAndIcon = (transactionStatus) => {
    if (transactionStatus.isPaid) return 'success';
    if (transactionStatus.isPending) return 'warning';
    return 'secondary';
  };

  const resolveTransactionTypeVariantAndIcon = (id) => {
    if (id === BANK_TRANSFER_ID)
      return { variant: 'success', icon: 'CheckIcon' };
    if (id === CASH_ID) return { variant: 'info', icon: 'DollarSignIcon' };
    return { variant: 'secondary', icon: 'XIcon' };
  };

  return {
    // Const
    CASH_ID,
    // Method
    createTransaction,
    downloadAccountStatement,
    fetchTaxiTrasactions,
    fetchTransactions,
    fetchTransactionStatus,
    fetchTransactionTypes,
    resolveTransactionStatusVariantAndIcon,
    resolveTransactionTypeVariantAndIcon,
    sendAccountStatement,
    cancelTransaction
  };
};

export default useTaxiTransaction;
