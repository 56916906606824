<template>
  <div>
    <b-alert
      variant="danger"
      :show="taxi === undefined"
    >
      <h4 class="alert-heading">
        Error fetching taxi data
      </h4>
      <div class="alert-body">
        No taxi found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'taxi-list' }"
        >
          Taxi List
        </b-link>
        for other companies.
      </div>
    </b-alert>

    <template v-if="taxi">
      <taxi-setting-button />

      <b-row class="match-height">
        <!-- Profile Taxi Card -->
        <b-col lg="4">
          <taxi-profile-card :taxi="taxi" @reload-taxi-data="initData"/>
        </b-col>

        <!-- Transactions Card -->
        <b-col
          lg="4"
          md="6"
        >
          <taxi-transactions-card />
        </b-col>

        <!-- Transactions Payout Card -->
        <b-col
          lg="4"
          md="6"
        >
          <taxi-transaction-payout-card />
        </b-col>

        <!-- Vehicles Card -->
        <b-col md="6">
          <taxi-vehicle-card />
        </b-col>

        <!-- Bank Account Card -->
        <b-col md="6">
          <taxi-bank-account-card />
        </b-col>

      </b-row>

    </template>

  </div>
</template>

<script>
import { BAlert, BButton, BCol, BDropdown, BDropdownItem, BLink, BRow } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import TaxiBankAccountCard from '@/modules/taxi/components/TaxiBankAccountCard.vue';
import TaxiProfileCard from '@/modules/taxi/components/TaxiProfileCard.vue';
import TaxiSettingButton from '@/modules/taxi/components/TaxiSettingButton.vue';
import TaxiTransactionPayoutCard from "@/modules/taxi/components/TaxiTransactionPayoutCard.vue";
import TaxiTransactionsCard from "@/modules/taxi/components/TaxiTransactionsCard.vue";
import TaxiVehicleCard from '@/modules/taxi/components/TaxiVehicleCard.vue';
import useTaxi from '@/modules/taxi/composables/useTaxi';

export default {
  setup() {
    const { fetchTaxi } = useTaxi();

    const taxi = ref(null);

    const initData = () => {
      fetchTaxi(router.currentRoute.params.id).then(({ standingOrders, vehicles, bankAccounts, ...rest }) => {
      store.commit('taxi/resetTaxiSignatureData')

      store.commit('taxiVehicle/resetVehicleList');
      store.commit('taxiVehicle/resetMainVehicle');
      store.commit('taxiVehicle/storeVehicles', vehicles);
      if (vehicles.length) {
        const mainVehicle = vehicles.find(vehicle => vehicle.isMainVehicle);
        if (mainVehicle) store.commit('taxiVehicle/storeMainVehicle', mainVehicle);
      }

      store.commit('taxiBankAccount/resetAccountList');
      store.commit('taxiBankAccount/resetMainAccount');
      store.commit('taxiBankAccount/storeAccounts', bankAccounts);
      if (bankAccounts.length) {
        const mainAccount = bankAccounts.find(account => account.isMain);
        if (mainAccount) store.commit('taxiBankAccount/storeMainAccount', mainAccount);
      }

      store.commit('standingOrder/storeStandingOrders', standingOrders);
      taxi.value = rest;
    }).catch(_ => taxi.value = undefined);
    }

    initData();

    return { taxi, initData};
  },
  components: {
    BAlert,
    BButton,
    BCol,
    BLink,
    BDropdown,
    BDropdownItem,
    BRow,
    TaxiBankAccountCard,
    TaxiProfileCard,
    TaxiSettingButton,
    TaxiTransactionPayoutCard,
    TaxiTransactionsCard,
    TaxiVehicleCard,
  }
};
</script>