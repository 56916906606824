<template>
  <b-modal
    id="taxi-account-list-modal"
    ref="refModal"
    size="lg"
    title="Taxi Bank Accounts List"
    ok-only
    ok-title="Close"
  >
    <div class="d-flex justify-content-end mt-1 mb-1">
      <b-button
        size="sm"
        variant="primary"
        @click="resetAccountData"
        v-b-modal.taxi-bank-account-modal
      >
        Add new
      </b-button>
    </div>

    <b-card
      class="account-list"
      no-body
    >
      <b-card-body>
        <div
          v-for="account in accountsArray"
          :key="account.id"
          class="account-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                :variant="(account.isMain) ? 'light-primary' : 'light-secondary'"
              >
                <feather-icon
                  size="18"
                  icon="DollarSignIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="w-100">
              <div class="d-flex align-items-center mb-50">
                <h6 class="mb-0">{{ account.bankName }}</h6>
                <div
                  v-if="account.isMain"
                  class="ml-1"
                >
                  <b-badge
                    variant="light-primary"
                    class="text-capitalize"
                  >
                    Main
                  </b-badge>
                </div>
              </div>
              <small>{{ account.bankAccountNum }}</small>
            </b-media-body>
          </b-media>

          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="editAccount(account)"
              v-b-modal.taxi-bank-account-modal
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="removeAccount(account)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-card-body>
    </b-card>


    <taxi-bank-account-modal
      :bankAccount="accountData"
      @form-submitted="taxiAccountFormSubmitted"
    />
  </b-modal>
</template>

<script>
import { BAvatar, BBadge, BButton, BCard, BCardBody, BCardText, BDropdown, BDropdownItem, BMedia, BMediaAside, BMediaBody, BModal } from "bootstrap-vue";
import { computed, ref } from "@vue/composition-api";
import router from '@/router';
import store from '@/store';
import Swal from 'sweetalert2';
import TaxiBankAccountModal from '@/modules/taxi/components/TaxiBankAccountModal.vue';
import useTaxiBankAccount from '@/modules/taxi/composables/useTaxiBankAccount';

export default {
  setup() {
    const { deleteBankAccount, getTaxiAccounts } = useTaxiBankAccount();

    const accountsArray = computed(() => store.getters['taxiBankAccount/accounts']);
    const accountData = ref(null);

    const editAccount = (data) => {
      accountData.value = { ...data };
    };

    const removeAccount = async (data) => {
      const { value } = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await deleteBankAccount(data);

      resetAccountData();
    };

    const resetAccountData = () => {
      accountData.value = {
        bankAccountNum: null,
        bankName: null,
        bankSortCode: null,
        id: null,
        isActive: null,
        taxiId: router.currentRoute.params.id,
      };
    };

    const taxiAccountFormSubmitted = async (_) => {
      const taxiId = router.currentRoute.params.id;
      await getTaxiAccounts(taxiId);
    };

    return {
      // Reactive
      accountData,
      accountsArray,

      // Method
      editAccount,
      removeAccount,
      resetAccountData,
      taxiAccountFormSubmitted
    };
  },
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BDropdown,
    BDropdownItem,
    BMedia,
    BMediaAside,
    BMediaBody,
    BModal,
    TaxiBankAccountModal
  }
};
</script>

<style>
.account-list .account-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account-list .account-item:not(:last-child) {
  margin-bottom: 1.2rem;
}

[dir=ltr] .account-list .account-item .avatar {
  margin-right: 1.2rem;
}

[dir=rtl] .account-list .account-item .avatar {
  margin-left: 1.2rem;
}

.account-list .account-item .avatar .avatar-content {
  width: 42px;
  height: 42px;
}
</style>