var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"refModal",attrs:{"id":"taxi-vehicle-modal","title":"Taxi Vehicle","ok-title":(_vm.vehicleData.id) ? 'Update' : 'Add',"no-close-on-backdrop":"","cancel-variant":"outline-secondary"},on:{"ok":_vm.validateForm}},[_c('validation-observer',{ref:"newTaxiVehicleForm"},[_c('b-form',{staticClass:"p-2",attrs:{"id":"add-new-taxi-vehicle"}},[_c('validation-provider',{attrs:{"name":"Manufacturer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Manufacturer","label-for":"manufacturer"}},[_c('b-form-input',{attrs:{"id":"manufacturer","placeholder":"Manufacturer","state":errors.length > 0 ? false : null,"name":"manufacturer"},model:{value:(_vm.vehicleData.manufacturer),callback:function ($$v) {_vm.$set(_vm.vehicleData, "manufacturer", $$v)},expression:"vehicleData.manufacturer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Model","label-for":"model"}},[_c('b-form-input',{attrs:{"id":"model","placeholder":"Model","state":errors.length > 0 ? false : null,"name":"model"},model:{value:(_vm.vehicleData.model),callback:function ($$v) {_vm.$set(_vm.vehicleData, "model", $$v)},expression:"vehicleData.model"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Year","label-for":"year"}},[_c('b-form-input',{attrs:{"id":"year","type":"number","placeholder":"Year","name":"year"},model:{value:(_vm.vehicleData.year),callback:function ($$v) {_vm.$set(_vm.vehicleData, "year", $$v)},expression:"vehicleData.year"}})],1),_c('validation-provider',{attrs:{"name":"Register Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Register Number","label-for":"register-number"}},[_c('b-form-input',{attrs:{"id":"register-number","type":"text","placeholder":"Register Number","state":errors.length > 0 ? false : null,"name":"register-number"},model:{value:(_vm.vehicleData.regNum),callback:function ($$v) {_vm.$set(_vm.vehicleData, "regNum", $$v)},expression:"vehicleData.regNum"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Number of Seats","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Number of Seats","label-for":"number-seats"}},[_c('b-form-input',{attrs:{"id":"number-seats","type":"number","placeholder":"Number of Seats","state":errors.length > 0 ? false : null,"name":"number-seats"},model:{value:(_vm.vehicleData.numSeats),callback:function ($$v) {_vm.$set(_vm.vehicleData, "numSeats", $$v)},expression:"vehicleData.numSeats"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Wheelchair Friendly","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Wheelchair Friendly","label-for":"wheelchair-friendly"}},[_c('v-select',{attrs:{"options":[{ label: 'Yes', value: true }, { label: 'No', value: false }],"label":"label","reduce":function (options) { return options.value; },"input-id":"wheelchair-friendly"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.vehicleData.wheelchairFriendly),callback:function ($$v) {_vm.$set(_vm.vehicleData, "wheelchairFriendly", $$v)},expression:"vehicleData.wheelchairFriendly"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Vehicle Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Vehicle Type","label-for":"vehicle-type"}},[_c('v-select',{attrs:{"options":[{ label: 'Main', value: true }, { label: 'Secondary', value: false }],"label":"label","reduce":function (options) { return options.value; },"input-id":"vehicle-type"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-primary' : 'bg-warning',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-primary' : 'bg-warning',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.vehicleData.isMainVehicle),callback:function ($$v) {_vm.$set(_vm.vehicleData, "isMainVehicle", $$v)},expression:"vehicleData.isMainVehicle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }