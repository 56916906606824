import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useTaxiStandingOrder = () => {
  const toast = useToast();

  const createStandingOrder = async (standingOrder) => {
    try {
      return await store.dispatch('standingOrder/create', standingOrder);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error creating the taxi standing order',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const editStandingOrder = async (standingOrder) => {
    try {
      await store.dispatch('standingOrder/update', standingOrder);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating the taxi standing order',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const getTaxiStandingOrders = async (taxiId) => {
    try {
      return await store.dispatch('standingOrder/getTaxiList', taxiId);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the taxi standing orders',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };
  return {
    createStandingOrder,
    editStandingOrder,
    getTaxiStandingOrders,
  };
};

export default useTaxiStandingOrder;
