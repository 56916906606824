var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"refModal",attrs:{"id":"taxi-bank-account-modal","title":"Bank Account Details","ok-title":(_vm.bankAccountData.id) ? 'Update' : 'Add',"no-close-on-backdrop":"","cancel-variant":"outline-secondary"},on:{"ok":_vm.validateForm}},[_c('validation-observer',{ref:"newTaxiBankAccountForm"},[_c('b-form',{attrs:{"id":"add-new-taxi-bank-account"}},[_c('validation-provider',{attrs:{"name":"Bank Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Bank Name","label-for":"bank-name"}},[_c('b-form-input',{attrs:{"id":"bank-name","placeholder":"Bank Name","state":errors.length > 0 ? false : null,"name":"bank-name"},model:{value:(_vm.bankAccountData.bankName),callback:function ($$v) {_vm.$set(_vm.bankAccountData, "bankName", $$v)},expression:"bankAccountData.bankName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Sort Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sort Code","label-for":"sort-code"}},[_c('b-form-input',{attrs:{"id":"sort-code","placeholder":"Sort Code","state":errors.length > 0 ? false : null,"name":"sort-code"},model:{value:(_vm.bankAccountData.bankSortCode),callback:function ($$v) {_vm.$set(_vm.bankAccountData, "bankSortCode", $$v)},expression:"bankAccountData.bankSortCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Account Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Account Number","label-for":"account-number"}},[_c('b-form-input',{attrs:{"id":"account-number","placeholder":"Account Number","state":errors.length > 0 ? false : null,"name":"account-number"},model:{value:(_vm.bankAccountData.bankAccountNum),callback:function ($$v) {_vm.$set(_vm.bankAccountData, "bankAccountNum", $$v)},expression:"bankAccountData.bankAccountNum"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Is Active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Is Active","label-for":"is-active"}},[_c('v-select',{attrs:{"options":[{ label: 'Yes', value: true }, { label: 'No', value: false }],"label":"label","reduce":function (options) { return options.value; },"input-id":"is-active"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.bankAccountData.isActive),callback:function ($$v) {_vm.$set(_vm.bankAccountData, "isActive", $$v)},expression:"bankAccountData.isActive"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Account Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Account Type","label-for":"account-type"}},[_c('v-select',{attrs:{"options":[{ label: 'Main', value: true }, { label: 'Secondary', value: false }],"label":"label","reduce":function (options) { return options.value; },"input-id":"account-type"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-primary' : 'bg-warning',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:(value) ? 'bg-primary' : 'bg-warning',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.bankAccountData.isMain),callback:function ($$v) {_vm.$set(_vm.bankAccountData, "isMain", $$v)},expression:"bankAccountData.isMain"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }